*{
margin: 0;
padding: 0;
box-sizing: border-box;
list-style: none;
text-decoration: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App{
  margin: 0 auto;
  position: relative;
}

a{
  display: block;
  width: 100%;
  height: 100%;
}

.not-found{
  width: clamp(400px, 80vw, 800px);
  margin: 1rem auto;
  text-align: center;
}

.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.back-home{
  margin: 0 auto;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 2px solid #000;
}

.back-home a{
  font-size: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.back-home a:visited{
  color: #000;
}

input{
  width: 100%;
  margin: 1rem 0;
  padding: 1rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #333;
}

.error{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  line-height: 3rem;
  font-size: 1.5rem;
}