nav ul{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
}

.nav-item{
    flex: 0 0 100%;
    height: 8rem;
    border-radius: 0.5rem;
    position: relative;
    background-color: linear-gradient(to right, #000000dd, #00000088);
    animation: move-bg-image 15s linear infinite forwards;
    animation-play-state: paused;
    box-shadow: 3px 3px 10px 2px #999;

}

.nav-item a{
    color: #fff;
    padding: 1rem;
    z-index: 2;
    font-size: 1.25rem;
}

.nav-item:nth-child(1){
    background-image: linear-gradient(to right, #000000dd, #00000088), url("../assets/countries-flags.png");
    background-size: 150%;
}
.nav-item:nth-child(2){
    background-image: linear-gradient(to right, #000000dd, #00000088), url("../assets/world-map-with-countries-and-capitals.jpg");
    background-size: 150%;
}
.nav-item:nth-child(3){
    background-image: linear-gradient(to right, #000000dd, #00000088), url("../assets/political-white-transparent-thin-world-map.png");
    background-size: 150%;
}

.nav-item:hover{
    animation-play-state: running;
}

@keyframes move-bg-image {
    0%{
        background-position: 10% 10%;
    }
    
    50%{
        background-position: 90% 30%;
    }
    
    100%{
        background-position: 30% 80%;
    }
}


@keyframes pan-bg-image {
    0%{
        background-position: 10% 5%;
    }
    50%{
        background-size: 120%;
        background-position: 80% 80%;
    }

    100%{
        background-size: 100%;
        background-position: 90% 15%;
    }
}
