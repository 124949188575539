
.home{
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

header{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.data-link {
    width: fit-content;
    margin: 0;
    padding: 0.5rem 1rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: rgb(0, 0, 91);
    color: #fff;

    border: none;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 3px 2px #ccc;
}
