.page-wrapper{
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.page-wrapper.flag{
    background-image: linear-gradient(to top, #000000dd, #00000088), url("../assets/countries-flags.png");
}

.page-wrapper.capital{
    background-image: linear-gradient(to top, #000000dd, #00000088), url("../assets/political-white-transparent-thin-world-map.png");
}

.page-wrapper.borders{
    background-image: linear-gradient(to top, #000000dd, #00000088), url("../assets/political-white-transparent-thin-world-map.png");
}

.quiz-wrapper{
    padding: 3rem;
    width: clamp(300px, 90vw, 900px);
    position: relative;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.34);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.36);
    
}

.quiz-card{
    width: 90%;
    height: 50vh;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 0.5rem;
}

.quiz-card::before{
    font-size: 4rem;
    position: absolute;
    top: 40%;
    right: 0.5rem;
    transform: translateY(-50%);
}

.quiz-card.true::before{
    content: "✅";
}
.quiz-card.false::before{
    content: "❌";
}

.score{
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 1.5rem;
    color: #000;
}

.flag-img{
    width: 80%;
    max-width: 500px;
    height: auto;
}

.card-text{
    color: #000;
    font-size: 1.5rem;
    text-align: center;
}

.card-text-big{
    display: block;
    font-size: 2.5rem;
}

.solution{
    margin: 2rem 0;
    font-size: 1.5rem;
    color: #000;
    text-align: center;
}

.not-guessed-list{
    color: darkred;
}

.quiz-form{
    width: clamp(400px, 80vw, 700px);
    width: 90%;
    margin: 1rem auto;
    height: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
    "input input"
    "skip check";
    gap: 0.5rem;
}

.quiz-form input{
    grid-area: input;
    margin: 0;
    background-color: #ffffff99;
    border: 2px solid #000;
    font-size: 1.125rem;
}

.quiz-form input:focus{
    outline: none;
}

.skip-btn{
    grid-area: skip;
}

.check-btn{
    grid-area: check;
}

.quiz-form button{
    padding: 1rem;
    cursor: pointer;
    background-color: #ffffff99;
    border: 2px solid #000;
    border-radius: 0.25rem;
}