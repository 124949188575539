.table-wrapper{
    padding: 1rem;
    margin: 0 auto;
    width: clamp(200px, 90vw, 1200px);
    background-color: #f7f7f7;
    min-height: 100vh;
}

.credit-link{
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    gap: 0.5rem;
    margin: 0.5rem 0;
}

.table-form{
    width: 100%;
    padding: 1rem 0;
}

.sort-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;
    gap: 0.5rem;
    white-space: nowrap;
    padding:  0.5rem 0 ;
}

.sort-btns::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.sort-btns::-webkit-scrollbar-track {
    background: #e3e3e3;
    border-radius: 50rem; 
}
.sort-btns::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 91); 
    border-radius:10px;
}

.sort{
    flex-grow: 1;
    padding: 0.75rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 0.25rem;
    cursor: pointer;
}

.sort:hover{
    background-color: #ddd;
}

.sort.active{
    background-color: rgb(0, 0, 91);
    color: #fff;
}

table{
    padding: 0.5rem;
    border-collapse: collapse;
    counter-reset: row;
    width: 100%;
}

thead{
    position: sticky;
    top: 0rem;
    background-color: rgb(0, 0, 91);
    color: #fff;
}

th{
    text-align: left;
}

tr{
    display: grid;
    /*                     nr    flag name capital population map area  */
    grid-template-columns: 0.5fr 1fr   2fr   2fr      2fr     1fr  2fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    border: 1px solid #333;
}

tr:nth-child(2n){
    background-color: hsl(240, 100%, 95%);
}

td:first-child{
    position: relative;
}

td:first-child:before{
    counter-increment: row;
    content: counter(row);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


th, td{   
    padding: 0.75rem;
    text-align: center;
}

.td-image{
    width: 3rem;
    height: 1.5rem;
    /* border: 1px solid #000; */
    /* background-color: #aaa; */
}

.back-to-top{
    width: fit-content;
    border-radius: 0.25rem;
    border: none;
    display: block;
    background-color: rgb(0, 0, 91);
    padding: 1rem;
    color: #fff;
    cursor: pointer;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    font-size: 1.2rem;
}
